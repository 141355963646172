var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        [
          _c(
            "oral-map",
            { attrs: { mapOptions: _vm.mapOptions, fullFilling: true } },
            [
              _c(
                "vue-ngw-control",
                { attrs: { position: "top-left", margin: true } },
                [
                  !_vm.drawer
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "rectangle-fab",
                          attrs: { fab: "", small: "", color: "#fff" },
                          on: {
                            click: function ($event) {
                              _vm.drawer = !_vm.drawer
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "drawe-icon",
                              class: { active: _vm.drawer },
                            },
                            [_vm._v(_vm._s(_vm.svg.chevron_right))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vue-ngw-control",
                { attrs: { position: "bottom-left", margin: true } },
                [
                  _vm.legendOpen
                    ? _c(
                        "div",
                        { staticClass: "d-flex flex-column" },
                        [
                          _vm.module.legendItems.length
                            ? _c(
                                "v-card",
                                {
                                  staticClass: "mx-auto legend-card",
                                  attrs: {
                                    "max-width": "300",
                                    "max-height": "500",
                                    dark: "",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "legend-header flex-header-content",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-space-between align-center",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "legend-card__title",
                                            },
                                            [_vm._v("Легенда")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "legend__close",
                                              attrs: { icon: "", small: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.legendOpen = false
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(_vm._s(_vm.svg.close)),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-grow-1 flex-body-content legend-body",
                                    },
                                    [_c("Legend", { staticClass: "pt-0" })],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "rectangle-fab",
                              attrs: { fab: "", small: "", color: "#fff" },
                              on: {
                                click: function ($event) {
                                  _vm.legendOpen = !_vm.legendOpen
                                },
                              },
                            },
                            [
                              _c("v-icon", { class: { active: _vm.drawer } }, [
                                _vm._v(_vm._s(_vm.svg.list)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-navigation-drawer",
        {
          attrs: { stateless: "", fixed: "", app: "", width: "360" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c("div", { staticClass: "drawer-content d-flex flex-column" }, [
            _c(
              "div",
              { staticClass: "flex-header-content" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "detail-drawer__header-close",
                    attrs: { text: "", icon: "", small: "", dark: "" },
                    on: {
                      click: function ($event) {
                        _vm.drawer = false
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v(_vm._s(_vm.svg.close))])],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "place-select" },
                  [_c("SelectPlace")],
                  1
                ),
                _vm._v(" "),
                _vm.filterPanelOpen
                  ? _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function ($event) {
                                _vm.filterPanelOpen = false
                              },
                            },
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "text--secondary mr-2" },
                              [_vm._v(_vm._s(_vm.svg.arrow_back))]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "text--secondary" }, [
                              _vm._v(" Вернуться к списку объектов "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "list-toolbar",
                        class: { shadowed: _vm.listIsScrolled },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-space-between align-center mb-4",
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "subtitle-1 font-weight-medium" },
                              [
                                _vm._v(
                                  "\n              Объекты\n              "
                                ),
                                _c(
                                  "v-chip",
                                  {
                                    staticClass:
                                      "list-toolbar__count font-weight-medium",
                                    attrs: { small: "" },
                                  },
                                  [
                                    _vm.isFilterSet
                                      ? [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.filtered.length) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text--secondary" },
                                            [
                                              _vm._v(
                                                "из " +
                                                  _vm._s(
                                                    _vm.activePlaceItems.length
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.activePlaceItems.length
                                              ) +
                                              "\n                "
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              [
                                _vm.isFilterSet
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "px-1",
                                        attrs: {
                                          small: "",
                                          text: "",
                                          color: "primary",
                                        },
                                        on: { click: _vm.resetNonPlaceFilter },
                                      },
                                      [
                                        _vm._v(
                                          "\n                Сбросить\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "filter-btn",
                                    attrs: { text: "", icon: "", small: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.filterPanelOpen = true
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "filter-btn",
                                        attrs: { color: "primary" },
                                        on: {
                                          click: function ($event) {
                                            _vm.filterPanelOpen = true
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.svg.filter))]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("v-text-field", {
                          attrs: {
                            loading: !_vm.searchReady,
                            "cache-items": "",
                            flat: "",
                            solo: "",
                            dense: "",
                            outlined: "",
                            "hide-no-data": "",
                            "hide-details": "",
                            clearable: "",
                            placeholder: "Поиск...",
                            "prepend-inner-icon": _vm.svg.search,
                          },
                          model: {
                            value: _vm.listSearchText,
                            callback: function ($$v) {
                              _vm.listSearchText = $$v
                            },
                            expression: "listSearchText",
                          },
                        }),
                      ],
                      1
                    ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex-grow-1 flex-body-content",
                attrs: { id: "panel-content" },
              },
              [
                _vm.items && _vm.items.length
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "scroll",
                            rawName: "v-scroll:#panel-content",
                            value: _vm.onPanelScroll,
                            expression: "onPanelScroll",
                            arg: "#panel-content",
                          },
                        ],
                        staticClass: "pb-3",
                      },
                      [
                        _vm.filterPanelOpen
                          ? _c("FilterPanel", {
                              on: {
                                close: function ($event) {
                                  _vm.filterPanelOpen = false
                                },
                              },
                            })
                          : _c("list", { staticClass: "pt-0" }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.featuresLoading
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "pa-3 text-center" },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "primary" },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "detail-drawer",
          attrs: {
            value: _vm.detailDrawer,
            stateless: "",
            width: "360",
            absolute: "",
            app: "",
            right: "",
          },
        },
        [
          _vm.detail
            ? _c("div", { staticClass: "drawer-content d-flex flex-column" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "scroll",
                        rawName: "v-scroll:#detail-content",
                        value: _vm.onDetailScroll,
                        expression: "onDetailScroll",
                        arg: "#detail-content",
                      },
                    ],
                    staticClass: "detail-drawer__header flex-header-content",
                    class: { shadowed: _vm.detailIsScrolled },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pb-3 d-flex justify-space-between align-center",
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "detail-drawer__header-close",
                            attrs: { text: "", icon: "", small: "" },
                            on: {
                              click: function ($event) {
                                _vm.detail = false
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v(_vm._s(_vm.svg.close))])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-chip",
                          {
                            staticClass:
                              "detail-drawer__header-chip text-uppercase font-weight-bold",
                            attrs: {
                              color:
                                _vm.detail.properties.status === "существующий"
                                  ? "#7bd235"
                                  : "#d2357b",
                              dark: "",
                              small: "",
                              label: "",
                            },
                          },
                          [_vm._v(_vm._s(_vm.detail.properties.status))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "subtitle-1 font-weight-medium" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.detail.properties.name) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "caption text--secondary" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.detail.properties.type) +
                          "\n        "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "detail-drawer__content flex-grow-1 flex-body-content",
                    attrs: { id: "detail-content" },
                  },
                  [_c("detail")],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "detail-drawer__footer flex-footer-content" },
                  [
                    _c(
                      "div",
                      { staticClass: "bottom-buttons" },
                      [
                        _c(
                          "div",
                          { staticClass: "bottom-buttons__item" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "primary" },
                                on: { click: _vm.openFeedbackPage },
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v(_vm._s(_vm.svg.feedback)),
                                ]),
                                _vm._v(
                                  "\n              Обратная связь\n            "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-divider", {
                          staticClass: "mx-1 my-2",
                          attrs: { inset: "", vertical: "" },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "bottom-buttons__item" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "primary" },
                                on: {
                                  click: function ($event) {
                                    _vm.zoomTo = _vm.detail.properties.id1
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v(_vm._s(_vm.svg.target)),
                                ]),
                                _vm._v(
                                  "\n              На карте\n            "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-divider", {
                          staticClass: "mx-1 my-2",
                          attrs: { inset: "", vertical: "" },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "bottom-buttons__item" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "primary" },
                                on: {
                                  click: function ($event) {
                                    _vm.shareDialog = true
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v(_vm._s(_vm.svg.share)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.shareDialog,
            callback: function ($$v) {
              _vm.shareDialog = $$v
            },
            expression: "shareDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5 grey lighten-2" }, [
                _vm._v(" Поделиться "),
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _vm.detail
                    ? _c("Share", { attrs: { item: _vm.detail } })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.shareDialog = false
                        },
                      },
                    },
                    [_vm._v("\n          Закрыть\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }